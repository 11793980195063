<template>
  <layout-vertical>
    <template v-slot:navbar="{toggleVerticalMenuActive}">
      <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
          <li class="nav-item">
            <b-link
                class="nav-link"
                @click="toggleVerticalMenuActive"
            >
              <feather-icon
                  icon="MenuIcon"
                  size="21"
              />
            </b-link>
          </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
          <b-navbar-nav class="nav align-items-center mr-auto">
            <!--                        <search-bar/>-->
            <b-nav-item
                v-for="(bookmark, index) in bookmarks"
                :id="`bookmark-${index}`"
                :key="index"
                :to="bookmark.route"
            >
              <feather-icon
                  :icon="bookmark.icon"
                  size="21"
              />
              <b-tooltip
                  triggers="hover"
                  :target="`bookmark-${index}`"
                  :title="bookmark.title"
                  :delay="{ show: 500, hide: 50 }"
              />
            </b-nav-item>
          </b-navbar-nav>
        </div>

        <ul class="navbar-nav nav align-items-center ml-auto">
          <!--          <dark-Toggler class="d-none d-lg-block" />-->
          <!--          <notification-dropdown/>-->
          <UserDropdown></UserDropdown>
        </ul>
      </div>
    </template>
    <router-view/>

    <app-customizer
        v-if="showCustomizer"
        slot="customizer"
    />

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import {$themeConfig} from '@themeConfig'
import UserDropdown from "@/views/components/shared/UserDropdown";
import {BLink, BNavbarNav, BNavItem, BTooltip} from "bootstrap-vue";
import SearchBar from "@core/layouts/components/app-navbar/components/SearchBar";


export default {
  components: {
    SearchBar,
    AppCustomizer,
    LayoutVertical,
    UserDropdown,
    BLink,
    BNavbarNav,
    BNavItem,
    BTooltip
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      bookmarks: [
        {title: 'Sales Tickets', route: {name: 'sales-tickets'}, icon: 'TrelloIcon'}
      ]
    }
  },
}
</script>

<template>
    <b-modal @hidden="reset" id="change-password-model" size="lg" centered ok-only ok-title="Close"
        title="Change Password">
        <div v-if="loading" class="loader-overlay">
            <b-spinner style="width: 4rem; height: 4rem;" variant="primary" />
        </div>
        <form ref="offerForm" color="#7367F0" :title="null" :subtitle="null" shape="square" finish-button-text="Submit"
            back-button-text="Previous" class="mb-3" @submit.prevent="submit">
            <validation-observer ref="form">

                <b-form-group label="Current password">
                    <validation-provider #default="{ errors }" name="Current password" rules="required">
                        <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input v-model="form.old_password" :state="errors.length > 0 ? false : null"
                                placeholder="Current password" :type="showOldPassword ? 'password' : 'text'"></b-form-input>
                            <b-input-group-append is-text>
                                <feather-icon class="cursor-pointer" :icon="showOldPassword ? 'EyeOffIcon' : 'EyeIcon'"
                                    @click="showOldPassword = !showOldPassword" />
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger" v-show="validationMinLengthCurrentPassword">{{ errors[0] }}</small>
                        <small class="text-danger" v-show="!validationMinLengthCurrentPassword">Password should be at least 8 chars and contains <br /> (at least 1 number and at least 1 letter)</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="New password">
                    <validation-provider #default="{ errors }" name="New password" rules="required">
                        <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input v-model="form.new_password" :state="errors.length > 0 ? false : null"
                                @input="val => {validationMinLengthNewPassword = validPassword(val)}"
                                placeholder="New password" :type="showNewPassword ? 'password' : 'text'"></b-form-input>
                            <b-input-group-append is-text>
                                <feather-icon class="cursor-pointer" :icon="showNewPassword ? 'EyeOffIcon' : 'EyeIcon'"
                                    @click="showNewPassword = !showNewPassword" />
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger" v-show="validationMinLengthNewPassword">{{ errors[0] }}</small>
                        <small class="text-danger" v-show="!validationMinLengthNewPassword">Password should be at least 8 chars and contains <br /> (at least 1 number and at least 1 letter)</small>
                        <small class="text-danger" v-show="!equalThem">new password don't equal the confirm new password</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Confirm new password">
                    <validation-provider #default="{ errors }" name="Confirm new password" rules="required">
                        <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input v-model="form.confirm_new_password" :state="errors.length > 0 ? false : null"
                                @input="val => {validationMinLengthConfirmNewPassword = validPassword(val)}"
                                placeholder="Confirm new password" :type="showConfirmPassword ? 'password' : 'text'"></b-form-input>
                            <b-input-group-append is-text>
                                <feather-icon class="cursor-pointer" :icon="showConfirmPassword ? 'EyeOffIcon' : 'EyeIcon'"
                                    @click="showConfirmPassword = !showConfirmPassword" />
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger" v-show="validationMinLengthConfirmNewPassword">{{ errors[0] }}</small>
                        <small class="text-danger" v-show="!validationMinLengthConfirmNewPassword">Password should be at least 8 chars and contains <br /> (at least 1 number and at least 1 letter)</small>
                        <small class="text-danger" v-show="!equalThem">new password don't equal the confirm new password</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </form>

        <template #modal-footer>
            <b-button @click="$bvModal.hide('change-password-model')" class="float-right">Close</b-button>
            <b-button @click="submit" class="float-right" variant="primary">Change Password</b-button>
        </template>
    </b-modal>
</template>

<script>
import AuthService from "@/services/AuthService"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BSpinner, BButton, BFormInput, BFormGroup, BInputGroupAppend, BInputGroup } from 'bootstrap-vue'
import { required } from '@validations'

export default {
    components: { BSpinner, BButton, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, ValidationProvider, ValidationObserver, required },
    data() {
        return {
            validationMinLengthConfirmNewPassword: true,
            validationMinLengthCurrentPassword: true,
            validationMinLengthNewPassword: true,
            authService: new AuthService(),
            showConfirmPassword: true,
            showNewPassword: true,
            showOldPassword: true,
            equalThem: true,
            loading: false,
            form: {},
        }
    },
    methods: {
        open() {
            this.$bvModal.show('change-password-model');
        },
        updatePassword() {
            this.loading = true;
            this.authService.updatePassword(this.form).then(res => {
                this.$bvModal.hide('change-password-model');
            }).catch(err => { }).finally(() => {
                this.loading = false;
            })
        },
        submit() {
            this.$refs.form.validate().then(success => {
                if(this.form.new_password != this.form.confirm_new_password){
                    this.equalThem = false
                }else{
                    this.equalThem = true
                }
                if (success && this.equalThem && this.validationMinLengthConfirmNewPassword && this.validationMinLengthCurrentPassword && this.validationMinLengthNewPassword) {
                    this.updatePassword();
                } else {
                }
            })
        },
        validPassword(val){
            return /\d/.test(val) && isNaN(val) && val.length >= 8; 
        },
        reset() {
            this.form = {};
        }
    },
}
</script>

<style scope>
.nav-tabs .nav-link.active {
    background: var(--primary);
    border-radius: 6px;
    color: #fff;
}

.tabs-border {
    border-left: 3px solid var(--primary);
}

.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}
</style>
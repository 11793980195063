import ApiService from "@/services/shared/http/ApiService";

export default class AuthService extends ApiService {
  forgetPassword(data) {
    return this.POST("forget-password", data);
  }

  resetPassword(data) {
    return this.POST("reset-password", data);
  }

  login(data) {
    return this.POST("login", data);
  }

  adminLogin(data) {
    return this.POST("admins/login", data);
  }

  adminForgetPassword(data) {
    return this.POST("admin/forget-password", data);
  }

  adminResetPassword(data) {
    return this.POST("admin/reset-password", data);
  }

  updatePassword(form) {
    return this.PATCH(`change-password`, form);
  }
}
